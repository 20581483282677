<template>
    <div>
        <navbar/>
        <mission-image/>
        <strombinsoscope class="mt100"/>
        <foot class="mt100"/>
    </div>
</template>

<style scoped>
.mission-image {
    background-image: url('../images/mission.jpg');
    background-color: var(--grisclair);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 750px;
    margin-bottom: 10px;
}

.mission-content {
    margin-top: 40px;
    width: 40%;
    position: absolute;
    left: 50%;
}

.mission-content p{
    margin-top: 50px;
}



</style>

<script>
import foot from '@/components/Footer.vue'
import navbar from '@/components/NavBar.vue'
import MissionImage from '../components/MissionImage.vue';
import strombinsoscope from '../components/MissionStrombinoscope.vue'
export default {
    components:{
        foot,
        navbar,
        MissionImage,
        strombinsoscope
    },
    metaInfo: {
        title: 'MiceTracking - Notre Mission : Innover en Recherche Biomédicale et Bien-être Animal',
        meta: [
            {
                name: 'description',
                content: 'Chez MiceTracking, nous innovons en recherche biomédicale avec nos implants de pointe, garantissant le bien-être animal et des mesures précises. Découvrez notre équipe dédiée à améliorer la santé animale.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, mission, recherche biomédicale, bien-être animal, implants, équipe, santé animale, innovation'
            }
        ]
    },

    methods: {
        handleMouseMove(event, imageClass) {
            const image = event.currentTarget.querySelector('.' + imageClass);
            const rect = event.currentTarget.getBoundingClientRect();
            image.style.display = 'block';
            image.style.left = (event.clientX - rect.left) + 'px';
            image.style.top = (event.clientY - rect.top) + 'px';
        },
        handleMouseLeave(event, imageClass) {
            const image = event.currentTarget.querySelector('.' + imageClass);
            image.style.display = 'none';
        }
    }
}
</script>